import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const JohnPackhamStoryPage = () => (

    <Layout pageClass="story-page">
    <SEO title="John Packham - John Packham Chartered Accountant - Story" />
    <main className="main">
        <div className="typography inner">
            <h1>John Packham Chartered Accountant</h1>          
            <p>I have grown my practice income by 351% since hiring Dave Wylie and his company Experience on Tap Limited. </p>

            <p>Dave has been my business mentor and coach for the past 9 years, we meet once a week and Dave's insightful advice has helped and guided me to:</p>

            <ul>
                <li>Step back and work on my business instead of in my business,</li>
                <li>Create more family time - we made 4 overseas trips last year,</li>
                <li>Reduce my work stress levels by assisting me with processes and systems,</li>
                <li>Hire a good team of people around me who share my values and look after my clients with a positive approach and fast response times.</li>
            </ul>

            <p> I continue to use Dave’s help as my mentor and advisor because he has that "X" factor - a unique ability to create ideas and concepts that I haven't thought of that continually help me improve my practice and create new openings and avenues for client satisfaction.</p>          
 
        </div>
    </main>
  </Layout>

)


export default JohnPackhamStoryPage